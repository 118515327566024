import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/main.css';
import logo from '../img/logo.png';
import { Navbar } from '../component/navbar';
import CardPreview from "../component/CardPreview";
import { api } from "../controllers/api_controller";

function CreateRaffle() {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('create');
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        startDate: '',
        endDate: '',
        creationDate: new Date().toISOString().split('T')[0],
        productValue: '',
        youtubeUrl: '',
        focus: false,
        winner: '',
        enable: true
    });
    const [imageFile, setImageFile] = useState(null);
    const [imageUrl, setImageUrl] = useState('');
    const [raffles, setRaffles] = useState([]);
    const [selectedRaffle, setSelectedRaffle] = useState(null);

    useEffect(() => {
        document.title = "Criar/Editar Campanha | Dashboard Larifa";
        window.scrollTo(0, 0);

        const token = localStorage.getItem('token');
        if (!token) {
            alert('User is not authenticated');
            navigate('/login');
            return;
        }

        fetchRaffles();

        const hash = window.location.hash;
        if (hash) {
            const element = document.getElementById(hash.replace('#', ''));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [navigate]);

    useEffect(() => {
        if (selectedRaffle) {
            setFormData({
                name: selectedRaffle.name,
                description: selectedRaffle.description,
                startDate: selectedRaffle.start_date,
                endDate: selectedRaffle.end_date,
                productValue: selectedRaffle.product_value,
                youtubeUrl: selectedRaffle.youtube_url,
                focus: selectedRaffle.focus,
                winner: selectedRaffle.winner || '',
                enable: selectedRaffle.enable
            });
            setImageUrl(selectedRaffle.card_image_url);
        }
    }, [selectedRaffle]);

    const fetchRaffles = async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            alert('User is not authenticated');
            navigate('/login');
            return;
        }

        try {
            const response = await fetch(`${api}/backoffice/campaign/all/list`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                const errorData = await response.json();
                console.error('Error:', errorData);
                alert('Ocorreu um erro durante a solicitação.');
                return;
            }

            const responseData = await response.json();
            setRaffles(responseData);
        } catch (error) {
            console.error('Error message:', error.message);
            alert('Falha ao buscar campanhas');
        }
    };

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        if (tab === 'create') {
            setFormData({
                name: '',
                description: '',
                startDate: '',
                endDate: '',
                creationDate: new Date().toISOString().split('T')[0],
                productValue: '',
                youtubeUrl: '',
                focus: false,
                enable: true
            });
            setImageFile(null);
            setImageUrl('');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const token = localStorage.getItem('token');
        if (!token) {
            alert('User is not authenticated');
            navigate('/login');
            return;
        }

        const formDataWithFile = new FormData();
        for (const key in formData) {
            formDataWithFile.append(key, formData[key]);
        }
        if (imageFile) {
            formDataWithFile.append('imageFile', imageFile);
        }

        try {
            const response = await fetch(`${api}/backoffice/campaign/create`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                body: formDataWithFile
            });

            if (!response.ok) {
                const errorData = await response.json();
                console.error('Error:', errorData);
                alert('Ocorreu um erro durante a solicitação.');
                return;
            }

            const responseData = await response.json();

            if (responseData.authError) {
                navigate('/login');
                return;
            }

            alert('Campanha criada com sucesso');
            fetchRaffles();
            setImageUrl(responseData.imageUrl);
            setFormData({
                name: '',
                description: '',
                startDate: '',
                endDate: '',
                creationDate: new Date().toISOString().split('T')[0],
                productValue: '',
                youtubeUrl: '',
                focus: false,
                enable: true
            });
            setImageFile(null);
            setImageUrl('');
        } catch (error) {
            console.error('Error message:', error.message);
            alert('Falha ao criar campanha');
        }
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();

        const token = localStorage.getItem('token');
        if (!token) {
            alert('User is not authenticated');
            navigate('/login');
            return;
        }

        const formDataWithFile = new FormData();
        for (const key in formData) {
            formDataWithFile.append(key, formData[key]);
        }
        if (imageFile) {
            formDataWithFile.append('imageFile', imageFile);
        }

        try {
            const response = await fetch(`${api}/backoffice/campaign/${selectedRaffle.campaign_id}/edit`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                body: formDataWithFile
            });

            if (!response.ok) {
                const errorData = await response.json();
                console.error('Error:', errorData);
                alert('Ocorreu um erro durante a solicitação.');
                return;
            }

            const responseData = await response.json();

            if (responseData.authError) {
                navigate('/login');
                return;
            }

            alert('Campanha editada com sucesso');
            fetchRaffles();
            setImageUrl(responseData.imageUrl);
        } catch (error) {
            console.error('Error message:', error.message);
            alert('Falha ao editar campanha');
        }
    };

    const handleChange = (e) => {
        const { name, value, checked, type, files } = e.target;

        if (name === 'imageFile') {
            setImageFile(files[0]);
            const fileUrl = URL.createObjectURL(files[0]);
            setImageUrl(fileUrl);
        } else if (name === 'youtubeUrl') {
            const embedUrl = `https://www.youtube.com/embed/${value}`;
            setFormData({
                ...formData,
                [name]: embedUrl,
            });
        } else {
            setFormData({
                ...formData,
                [name]: type === 'checkbox' ? checked : value,
            });
        }
    };

    const handleEditChange = (e) => {
        const { name, value, checked, type, files } = e.target;

        if (name === 'imageFile') {
            setImageFile(files[0]);
            const fileUrl = URL.createObjectURL(files[0]);
            setImageUrl(fileUrl);
        } else if (name === 'youtubeUrl') {
            const embedUrl = `https://www.youtube.com/embed/${value}`;
            setFormData({
                ...formData,
                [name]: embedUrl,
            });
        } else {
            setFormData({
                ...formData,
                [name]: type === 'checkbox' ? checked : value,
            });
        }
    };

    const handleToggleEnable = async (campaignId, enable) => {
        const token = localStorage.getItem('token');
        if (!token) {
            alert('User is not authenticated');
            navigate('/login');
            return;
        }

        try {
            const response = await fetch(`${api}/backoffice/campaign/${campaignId}/toggle`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ enable: !enable })
            });

            if (!response.ok) {
                const errorData = await response.json();
                console.error('Error:', errorData);
                alert('Ocorreu um erro durante a solicitação.');
                return;
            }

            alert('Estado da campanha alterado com sucesso');
            fetchRaffles();
            setFormData({
                ...formData,
                enable: (formData.enable ? false : true),
            });
        } catch (error) {
            console.error('Error message:', error.message);
            alert('Falha ao alterar estado da campanha');
        }
    };

    const handleEditClick = (raffle) => {
        setSelectedRaffle(raffle);
        setActiveTab('edit');
        setFormData({
            name: raffle.name,
            description: raffle.description,
            startDate: raffle.start_date,
            endDate: raffle.end_date,
            productValue: raffle.product_value,
            youtubeUrl: raffle.youtube_url,
            focus: raffle.focus,
            winner: raffle.winner || '',
            enable: raffle.enable
        });
        setImageUrl(raffle.card_image_url);
    };

    return (
        <>
            <div className='top-bar'>
                <img src={logo} alt='Logo Thiago' />
                <p>Dashboard - Criar/Editar Campanha</p>
            </div>
            <Navbar />
            <div className="container-offset">
                <div className="content-holder">
                    <div className="tabs">
                        <div className={`tab ${activeTab === 'create' ? 'active' : ''}`} onClick={() => handleTabClick('create')}>
                            Criar Campanha
                        </div>
                        <div className={`tab ${activeTab === 'edit' ? 'active' : ''}`} onClick={() => handleTabClick('edit')}>
                            Editar Campanha
                        </div>
                    </div>
                    <div className="tab-content">
                        {activeTab === 'create' && (
                            <div className="form-card">
                                <form className='raffle-create' onSubmit={handleSubmit}>
                                    <div className='form-row'>
                                        <div className='form-group'>
                                            <label>Nome da campanha</label>
                                            <input className='campaign-text form-group-input' id='name' type='text' name='name' value={formData.name} onChange={handleChange} />
                                        </div>
                                        <div className='form-group'>
                                            <label>Descrição</label>
                                            <input className='campaign-text form-group-input' id='description' type='text' name='description' value={formData.description} onChange={handleChange} />
                                        </div>
                                        <div className='form-group'>
                                            <label>Inicio da campanha</label>
                                            <input className='campaign-date form-group-input' id='startDate' type='date' name='startDate' value={formData.startDate} onChange={handleChange} />
                                        </div>
                                        <div className='form-group'>
                                            <label>Data final da campanha</label>
                                            <input className='campaign-date form-group-input' id='endDate' type='date' name='endDate' value={formData.endDate} onChange={handleChange} />
                                        </div>
                                        <div className='form-group'>
                                            <label>Valor do Produto</label>
                                            <input className='campaign-text form-group-input' id='productValue' type='text' name='productValue' value={formData.productValue} onChange={handleChange} />
                                        </div>
                                        <div className='form-group'>
                                            <label>Código do vídeo do YouTube</label>
                                            <input
                                                className='campaign-text form-group-input'
                                                id='youtubeUrl'
                                                type='text'
                                                name='youtubeUrl'
                                                value={formData.youtubeUrl.split('/').pop()}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className='form-group'>
                                            <label>Imagem do Card</label>
                                            <input className='campaign-text form-group-input' id='imageFile' type='file' name='imageFile' onChange={handleChange} />
                                        </div>
                                        <div className='form-group'>
                                            <label className='focus'>
                                                <input type='checkbox' name='focus' checked={formData.focus} onChange={handleChange} />
                                                . Campanha em Destaque
                                            </label>
                                        </div>
                                    </div>
                                    <div className='focus-img-holder'>
                                        <CardPreview campaign={{ ...formData, imageUrl }} />
                                        {formData.youtubeUrl && (
                                            <div className='youtube-preview'>
                                                <iframe
                                                    width="560"
                                                    height="315"
                                                    src={formData.youtubeUrl}
                                                    title="YouTube video player"
                                                    frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowFullScreen
                                                ></iframe>
                                            </div>
                                        )}
                                        <button className='button-create' type='submit'>CRIAR NOVA CAMPANHA</button>
                                    </div>
                                </form>
                            </div>
                        )}
                        {activeTab === 'edit' && (
                            <div className="form-card">
                                <div className="raffles-edit-list">
                                    <select onChange={(e) => {
                                        const selectedId = e.target.value;
                                        const selectedRaffle = raffles.find(raffle => raffle.campaign_id.toString() === selectedId);
                                        handleEditClick(selectedRaffle);
                                    }}>
                                        <option value="" disabled selected>Selecione uma campanha</option>
                                        {raffles.map((raffle) => (
                                            <option key={raffle.campaign_id} value={raffle.campaign_id}>
                                                {raffle.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                {selectedRaffle && (
                                    <form className='raffle-edit' onSubmit={handleEditSubmit}>
                                        <div className='form-row'>
                                            <div className='form-group'>
                                                <label>Nome da campanha</label>
                                                <input className='campaign-text form-group-input' id='name' type='text' name='name' value={formData.name} onChange={handleEditChange} />
                                            </div>
                                            <div className='form-group'>
                                                <label>Descrição</label>
                                                <input className='campaign-text form-group-input' id='description' type='text' name='description' value={formData.description} onChange={handleEditChange} />
                                            </div>
                                            <div className='form-group'>
                                                <label>Inicio da campanha</label>
                                                <input className='campaign-date form-group-input' id='startDate' type='date' name='startDate' value={formData.startDate} onChange={handleEditChange} />
                                            </div>
                                            <div className='form-group'>
                                                <label>Data final da campanha</label>
                                                <input className='campaign-date form-group-input' id='endDate' type='date' name='endDate' value={formData.endDate} onChange={handleEditChange} />
                                            </div>
                                            <div className='form-group'>
                                                <label>Valor do Produto</label>
                                                <input className='campaign-text form-group-input' id='productValue' type='text' name='productValue' value={formData.productValue} onChange={handleEditChange} />
                                            </div>
                                            <div className='form-group'>
                                                <label>Código do vídeo do YouTube</label>
                                                <input
                                                    className='campaign-text form-group-input'
                                                    id='youtubeUrl'
                                                    type='text'
                                                    name='youtubeUrl'
                                                    value={formData.youtubeUrl.split('/').pop()}
                                                    onChange={handleEditChange}
                                                />
                                            </div>
                                            <div className='form-group'>
                                                <label>Imagem do Card</label>
                                                <input className='campaign-text form-group-input' id='imageFile' type='file' name='imageFile' onChange={handleEditChange} />
                                            </div>
                                            <div className='form-group'>
                                                <label className='focus'>
                                                    <input type='checkbox' name='focus' checked={formData.focus} onChange={handleEditChange} />
                                                    . Campanha em Destaque
                                                </label>
                                            </div>
                                            <div className='form-group'>
                                                <label>Vencedor</label>
                                                <input
                                                    className='campaign-text form-group-input'
                                                    id='winner'
                                                    type='text'
                                                    name='winner'
                                                    value={formData.winner}
                                                    onChange={handleEditChange}
                                                />
                                            </div>
                                        </div>
                                        <div className='focus-img-holder'>
                                            <CardPreview campaign={{ ...formData, imageUrl }} />
                                            {formData.youtubeUrl && (
                                                <div className='youtube-preview'>
                                                    <iframe
                                                        width="560"
                                                        height="315"
                                                        src={formData.youtubeUrl}
                                                        title="YouTube video player"
                                                        frameBorder="0"
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                        allowFullScreen
                                                    ></iframe>
                                                </div>
                                            )}
                                            <button className='button-create' type='submit'>EDITAR CAMPANHA</button>
                                            <button className='button-create mt-4' type='button' onClick={() => handleToggleEnable(selectedRaffle.campaign_id, formData.enable)}>
                                                {formData.enable ? 'DESATIVAR CAMPANHA' : 'ATIVAR CAMPANHA'}
                                            </button>
                                        </div>
                                    </form>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default CreateRaffle;
