import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBInput } from 'mdb-react-ui-kit';
import { FaWhatsapp } from 'react-icons/fa';
import logo from '../img/logo.png';
import { Navbar } from '../component/navbar';
import { fetchAllRafflesOfCampaigns } from '../controllers/rafflesController';
import { useNavigate } from 'react-router-dom';

function Raffle() {
    const navigate = useNavigate();
    const { id } = useParams();

    const [filterValues, setFilterValues] = useState({
        raffleNumber: '',
        buyerName: '',
        phone: '',
        email: '',
        itemsPerPage: 25 // Número de itens por página
    });
    const [allRaffles, setAllRaffles] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);

    // Carrega as rifas ao montar e quando o id da campanha mudar
    useEffect(() => {
        document.title = 'Rifa | Dashboard Larifa';
        setLoading(true);
        const loadRaffles = async () => {
            const rafflesData = await fetchAllRafflesOfCampaigns(id);
            if (rafflesData.authError) {
                navigate('/login');
            }
            setAllRaffles(rafflesData);
            setLoading(false);
        };
        loadRaffles();
    }, [id, navigate]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFilterValues(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleItemsPerPageChange = (event) => {
        setFilterValues(prevState => ({
            ...prevState,
            itemsPerPage: parseInt(event.target.value, 10)
        }));
        setCurrentPage(1); // Resetar para a primeira página quando mudar o número de itens por página
    };

    const filteredRaffles = useCallback(() => {
        return allRaffles.filter(raffle => {
            return (
                raffle['Número da Rifa'].toString().toLowerCase().includes(filterValues.raffleNumber.toLowerCase()) &&
                raffle['Nome do Comprador'].toLowerCase().includes(filterValues.buyerName.toLowerCase()) &&
                raffle['Telefone'].toLowerCase().includes(filterValues.phone.toLowerCase()) &&
                raffle['E-mail'].toLowerCase().includes(filterValues.email.toLowerCase())
            );
        });
    }, [allRaffles, filterValues]);

    const displayedRaffles = useCallback(() => {
        const startIndex = (currentPage - 1) * filterValues.itemsPerPage;
        return filteredRaffles().slice(startIndex, startIndex + filterValues.itemsPerPage);
    }, [filteredRaffles, currentPage, filterValues.itemsPerPage]);

    const totalPages = useCallback(() => {
        return Math.ceil(filteredRaffles().length / filterValues.itemsPerPage);
    }, [filteredRaffles, filterValues.itemsPerPage]);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const renderPaginationButtons = () => {
        const total = totalPages();
        const buttons = [];

        // Botão para a primeira página
        if (currentPage > 3) {
            buttons.push(
                <button className='page-button' key="first" onClick={() => handlePageChange(1)}>
                    Primeira
                </button>
            );
        }

        // Páginas próximas da atual
        for (let i = Math.max(1, currentPage - 2); i <= Math.min(total, currentPage + 2); i++) {
            buttons.push(
                <button
                    className='page-button'
                    key={i}
                    onClick={() => handlePageChange(i)}
                    disabled={currentPage === i}
                >
                    {i}
                </button>
            );
        }

        // Botão para a última página
        if (currentPage < total - 2) {
            buttons.push(
                <button className='page-button' key="last" onClick={() => handlePageChange(total)}>
                    Última
                </button>
            );
        }

        return buttons;
    };

    const formatPhoneNumber = (phone) => {
        // Remove todos os caracteres que não são números
        let formattedPhone = phone.replace(/\D/g, '');

        // Adiciona o código do país, se necessário
        if (!formattedPhone.startsWith('55')) {
            formattedPhone = '55' + formattedPhone;
        }

        return formattedPhone;
    };

    return (
        <>
            <div className='top-bar'>
                <img src={logo} alt='Logo Larifa' />
                <p>Dashboard - Rifa</p>
            </div>
            <Navbar />
            <MDBContainer fluid className='container-offset'>
                <div className='content-holder'>
                    <MDBRow className='main-dashboard-screen'>
                        <MDBCard className='form-card'>
                            <label style={{ borderLeft: '5px solid #980000', paddingLeft: '7px' }}>
                                Utilize os campos abaixo para filtrar as rifas
                            </label>
                            <form className='raffle-filter'>
                                <MDBInput label='Número da Rifa' id='raffleNumber' type='text' name='raffleNumber' onChange={handleInputChange} />
                                <MDBInput label='Nome do Comprador' id='buyerName' type='text' name='buyerName' onChange={handleInputChange} />
                                <MDBInput label='Número de Telefone' id='phone' type='text' name='phone' onChange={handleInputChange} />
                                <MDBInput label='E-mail' id='email' type='email' name='email' onChange={handleInputChange} />
                                <MDBInput label='Itens por página' id='itemsPerPage' type='number' name='itemsPerPage' value={filterValues.itemsPerPage} onChange={handleItemsPerPageChange} />
                            </form>
                        </MDBCard>
                        <MDBCol md={12}>
                            <MDBCard className='raffle-list'>
                                <h3>Rifas da Campanha</h3>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Número da Rifa</th>
                                            <th>Nome do Comprador</th>
                                            <th>Status</th>
                                            <th>Telefone</th>
                                            <th>E-mail</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {displayedRaffles().map((raffle, index) => {
                                            const phone = raffle["Telefone"];
                                            const formattedPhone = phone ? formatPhoneNumber(phone) : null;
                                            return (
                                                <tr key={index}>
                                                    <td>{raffle["Número da Rifa"]}</td>
                                                    <td>{raffle["Nome do Comprador"]}</td>
                                                    <td>{raffle["Status"]}</td>
                                                    <td>
                                                        {phone}
                                                        {formattedPhone && (
                                                            <a
                                                                href={`https://wa.me/${formattedPhone}`}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                <FaWhatsapp style={{ marginLeft: '10px', color: '#25D366', fontSize: '1.2em' }} />
                                                            </a>
                                                        )}
                                                    </td>
                                                    <td>{raffle["E-mail"]}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>

                                </table>
                                {loading && <p>Carregando mais...</p>}
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol>
                            <div className='pagination'>
                                {renderPaginationButtons()}
                            </div>
                        </MDBCol>
                    </MDBRow>
                </div>
            </MDBContainer>
        </>
    );
}

export default Raffle;
