import React, { useState, useEffect } from 'react';
import styles from '../styles/navbar.module.css';
import { MDBContainer, MDBRow, MDBCol, MDBCard } from 'mdb-react-ui-kit';
import { BsBoxArrowInRight, BsBoxArrowLeft } from "react-icons/bs";
import { BiBook, BiBookAdd } from "react-icons/bi";
import { IoMdPeople } from "react-icons/io";
import { MdOutlineAttachMoney } from "react-icons/md";
import { IoTicketOutline } from "react-icons/io5";
import { fetchAllCampaigns } from '../controllers/rafflesController';
import { AiOutlineEdit } from "react-icons/ai";


function Navbar() {

    const [collapsed, setCollapsed] = useState(true);
    const [campaigns, setCampaigns] = useState([]);

    useEffect(() => {
        const loadCampaigns = async () => {
            const campaignsData = await fetchAllCampaigns();
            if (campaignsData.authError) {
                setCampaigns([]);
                return;
            }
            setCampaigns(campaignsData); // Atualize o estado com as campanhas recebidas
        };
        loadCampaigns();
    }, []);

    const renderCampaignsDropdown = () => {
        return (
            <div className={styles.dropdown}>
                {campaigns.map(campaign => (
                    <a key={campaign.campaign_id} href={`/campanha/${campaign.campaign_id}`}>{campaign.name}</a> // Use campaign_id e name
                ))}
            </div>
        );
    };

    const toggleCollapse = () => {
        setCollapsed(!collapsed);
    };

    const containerClass = `${styles.navbarContainer} ${collapsed ? styles.navbarCollapsed : ''}`;

    return (
        <div style={{ display: 'flex', position: 'fixed', top: '0vh', zIndex: '4' }}>
            <div className={containerClass}>
                <div className={styles.navbarIconsHolder}>
                    <a href='/'>
                        {collapsed ? <BiBook /> : <div className={styles.navbarIcons}><BiBook /> Todas Campanhas</div>}
                    </a>
                    <a href='/usuarios'>
                        {collapsed ? <IoMdPeople /> : <div className={styles.navbarIcons}><IoMdPeople /> Usuários</div>}
                    </a>
                    <a href='/pagamentos'>
                        {collapsed ? <MdOutlineAttachMoney /> : <div className={styles.navbarIcons}><MdOutlineAttachMoney /> Pagamentos</div>}
                    </a>
                    <a href='/criar-campanha'>
                        {collapsed ? <AiOutlineEdit /> : <div className={styles.navbarIcons}><AiOutlineEdit /> Gerenciar Campanhas</div>}
                    </a>
                    <div className={styles.dropdownContainer}>
                        <a href='#' onClick={toggleCollapse}>
                            {collapsed ? <IoTicketOutline /> : <div className={styles.navbarIcons}><IoTicketOutline /> Campanhas</div>}
                        </a>
                        {!collapsed && renderCampaignsDropdown()}
                    </div>
                </div>
            </div>
            <div className={styles.navbarButtonHolder}>
                <button className={styles.navbarButton} onClick={toggleCollapse}>{collapsed ? <BsBoxArrowInRight /> : <BsBoxArrowLeft />}</button>
            </div>
        </div>
    );
}

export { Navbar };
