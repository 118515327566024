import React, { useEffect, useState } from 'react';
import '../css/cardRifa.css';
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import { api } from "../controllers/api_controller";

const CardRifa = ({ campaign, openLoginModal }) => {
    const defaultImage = 'https://fakeimg.pl/400x400';
    const [rifaImage, setRifaImage] = useState(defaultImage);

    useEffect(() => {
        const imageUrl = api + '/' + campaign.imageUrl;
        if (campaign.imageUrl) {
            checkImage(imageUrl, (exists) => {
                if (exists) {
                    setRifaImage(imageUrl);
                } else {
                    setRifaImage(defaultImage);
                }
            });
        }
    }, [campaign.imageUrl]);

    const checkImage = (url, callback) => {
        const img = new Image();
        img.src = url;
        img.onload = () => callback(true);
        img.onerror = () => callback(false);
    };

    return (
        <>
            <MDBContainer className='p-0 m-0 card-container'>
                <div className="rifa-card">
                    <MDBRow className='p-0 m-0 content-wrapper'>
                        <MDBCol className='img-wrapper' size={4}>
                            <img src={rifaImage} alt="Rifa" />
                        </MDBCol>
                        <MDBCol className='p-2 m-0 text-wrapper'>
                            <div className="rifa-info">
                                <div>
                                    <h2 className='m-0'>{campaign.name || `Nome da Campanha`}</h2>
                                    <p>{campaign.description || `Descrição da Campanha`}</p>
                                </div>
                                <div className="button-price">
                                    <span className="rifa-price">R${campaign.product_value || `0.00`}</span>
                                    <button className='button-confirm' disabled>Comprar rifa</button>
                                </div>
                            </div>
                        </MDBCol>
                    </MDBRow>
                </div>
            </MDBContainer>
        </>
    );
}

export default CardRifa;
